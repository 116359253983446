<template>
    <!-- 农资管理 -->
<div class="farm-data">
    <a-card>
        <template #title>
            <a-space>
                <a-button type="primary" @click="showEditFarmData" v-has="{action:'wxapp_storage_farmdata_add',plat:0}">
                    <i class="ri-add-line ri-btn"></i>新增农资
                </a-button>
                <com-upload-btn
                    v-has="{action:'wxapp_storage_farmdata_import',plat:0}"
                    btn-text="批量导入"
                    btn-type="default"
                    :customUpload="true"
                    @uploadChange="importFarmData" >
                </com-upload-btn>
                <a-button @click="showFdUnit" v-has="{action:'wxapp_storage_farmdata_unit',plat:0}">计量单位</a-button>
            </a-space>
        </template>
        <template #extra>
            <a-input-search
                v-model:value="fdState.search.key"
                placeholder="输入客户编号/名称/联系人/电话/备注查询"
                enter-button
                style="width:400px"
                @search="getFarmData(1,fdState.limit)"
            />
        </template>
        <a-table :pagination="false" rowKey="id" :data-source="fdState.list" :columns="[
                {title:'农资编号',dataIndex:'id'},
                {title:'图片',dataIndex:'images',slots: {customRender: 'images'}},
                {title:'农资名称',dataIndex:'name'},
                {title:'农资条码',dataIndex:'bar_code'},
                {title:'规格型号',dataIndex:'spec_model'},
                {title:'首选仓库',dataIndex:'depot_id'},
                {title:'质保天数',dataIndex:'early_warning'},
                {title:'当前库存',dataIndex:'stock'},
                {title:'状态',dataIndex:'status',slots: {customRender: 'status'}},
                {title:'备注',dataIndex:'remark'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: fdState.pks, onChange:(e)=>{fdState.pks = e}  }">
            <template #images="{record}">
                <a-image width="50px"
                         :src="record.images[0]"
                         v-if="record.images && record.images.length > 0">
                </a-image>
            </template>
            <template #status="{record}">
                <a-tooltip :title="record.status === 1 ?'切换状态为禁用':'切换状态为启用'">
                    <a-tag class="pt" :color="record.status ?'#00CC66':'#FF0066'"
                           @click="changeFarmData([record.id],record.status === 1 ?0:1)">
                        {{record.status === 1 ?'启用':'禁用'}}
                    </a-tag>
                </a-tooltip>
            </template>
            <template #action="{record}">
                <kd-button title="编辑"
                           icon="ri-edit-line"
                           @click="showEditFarmData(record)"
                           v-has="{action:'wxapp_storage_farmdata_add',plat:0}">
                </kd-button>
                <kd-button title="删除"
                           icon="ri-delete-bin-2-line"
                           type="danger"
                           v-has="{action:'wxapp_storage_farmdata_delete',plat:0}"
                           @click="deleteFarmData([record.id])">
                </kd-button>
            </template>
        </a-table>

        <kd-pager :page-data="fdState" :event="getFarmData">
            <a-space>
                <a-button @click="changeFarmData(fdState.pks,0)"
                          v-has="{action:'wxapp_storage_farmdata_status',plat:0}">禁用
                </a-button>
                <a-button @click="changeFarmData(fdState.pks,1)"
                          v-has="{action:'wxapp_storage_farmdata_status',plat:0}">启用
                </a-button>
                <a-tooltip title="未勾选要导出的数据时，导出的为导入模板">
                    <a-button @click="exportFarmData"
                              v-has="{action:'wxapp_storage_farmdata_export',plat:0}">导出（数据/模板）
                    </a-button>
                </a-tooltip>
                <a-button @click="deleteFarmData(fdState.pks)"
                          v-has="{action:'wxapp_storage_farmdata_delete',plat:0}">删除
                </a-button>
            </a-space>
        </kd-pager>
    </a-card>

    <a-modal title="添加农资" v-model:visible="efdState.show" @ok="saveFarmData" width="1000px">
        <a-form :label-col="{span:6}" :wrapper-col="{span:18}">
            <a-row :gutter="[16,16]">
                <a-col :span="12">
                    <a-form-item label="农资名称" required>
                        <a-input v-model:value="efdState.form.name"></a-input>
                    </a-form-item>
                    <a-form-item label="规格型号">
                        <a-input v-model:value="efdState.form.spec_model"></a-input>
                    </a-form-item>
                    <a-form-item label="农资条码">
                        <a-input v-model:value="efdState.form.bar_code"></a-input>
                    </a-form-item>
                    <a-form-item label="首选仓库">
                        <a-select v-model:value="efdState.form.depot_id" placeholder="首选仓库">
                            <a-select-option :value="0">选择首选仓库</a-select-option>
                            <a-select-option v-for="(item,index) in efdState.enterPot"
                                             :key="index" :value="item.id">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="农资图片">
                        <kd-img-select :mutil="true" :src="efdState.form.images"
                                       @success="e=>efdState.form.images = e">
                        </kd-img-select>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="计量单位">
                        <a-select v-model:value="efdState.form.unit_id" placeholder="计量单位">
                            <a-select-option :value="0">选择计量单位</a-select-option>
                            <a-select-option v-for="(item,index) in fduState.list" :key="index" :value="item.id">
                                {{item.name}}
                            </a-select-option>
                            <template #dropdownRender="{ menuNode: menu }">
                                <v-nodes :vnodes="menu" />
                                <a-divider style="margin: 4px 0" />
                                <div style="padding: 4px 8px; cursor: pointer"
                                     @mousedown="e => e.preventDefault()"
                                     @click="showEditFdUnit(null)"
                                >
                                    <i class="ri-add-line ri-top"></i>添加计量单位
                                </div>
                            </template>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="质保预警">
                        <a-input v-model:value="efdState.form.early_warning" addon-after="天"></a-input>
                    </a-form-item>
                    <a-form-item label="库存预警">
                        <a-space>
                            <a-input v-model:value="efdState.form.upper_warning_limit" placeholder="上限" addon-after="上限"></a-input>
                            ———
                            <a-input v-model:value="efdState.form.lower_waring_limit" placeholder="下限" addon-after="下限"></a-input>
                        </a-space>
                    </a-form-item>
                    <a-form-item label="备注">
                        <a-textarea v-model:value="efdState.form.remark" placeholder="备注" :rows="4" />
                    </a-form-item>
                </a-col>
            </a-row>

        </a-form>
    </a-modal>

    <a-modal title="添加计量单位" v-model:visible="euState.show" @ok="saveFdUnit" :z-index="9999">
        <a-form :label-col="{span:4}" :wrapper-col="{span:16}" v-if="euState.form">
            <a-form-item label="名称">
                <a-input v-model:value="euState.form.name"></a-input>
            </a-form-item>
        </a-form>
    </a-modal>
    <a-modal title="计量单位" v-model:visible="fduState.show" :footer="null" width="700px">
        <a-table :pagination="false" rowKey="id" :data-source="fduState.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'产品名称',dataIndex:'name'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]">
            <template #action="{record}">
                <kd-button title="编辑"
                           icon="ri-edit-line"
                           @click="showEditFdUnit(record)"
                           v-has="{action:'wxapp_storage_farmdata_unit_add',plat:0}">
                </kd-button>
                <kd-button title="删除"
                           icon="ri-delete-bin-2-line"
                           type="danger"
                           @click="changeFdUnitStatus(record)"
                           v-has="{action:'wxapp_storage_farmdata_unit_delete',plat:0}">
                </kd-button>
            </template>
        </a-table>
    </a-modal>

</div>
</template>
<script>
import { useFarmData,useEditFarmData,useFarmDataUnit ,useEditFarmDataUnit} from '@/models/addons/storage'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
export default {
    components:{
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
        comUploadBtn
    },
    setup() {


        //农资信息
        let { fdState,getFarmData,changeFarmData ,deleteFarmData,exportFarmData,importFarmData } = useFarmData()
        //新增、编辑农资信息
        let { efdState,showEditFarmData ,saveFarmData } = useEditFarmData(fdState,getFarmData )
        //农资计量单位
        let { fduState,getFdUnit,showFdUnit,changeFdUnitStatus } = useFarmDataUnit()
        //农资计量单位新增、编辑
        let { euState,showEditFdUnit,saveFdUnit } = useEditFarmDataUnit(getFdUnit)

        getFarmData(1,fdState.limit)
        getFdUnit()

        return{
            fdState,getFarmData,changeFarmData ,deleteFarmData,exportFarmData,importFarmData,
            efdState,showEditFarmData ,saveFarmData,
            fduState,getFdUnit,showFdUnit,changeFdUnitStatus,
            euState,showEditFdUnit,saveFdUnit
        }
    },
}
</script>
<style scoped lang="scss">
.farm-data{
    .pager{
        display: flex;
        justify-content: space-between;
    }
    .dc-form{
        display: flex;
        flex-wrap: nowrap;
    }
}
</style>
